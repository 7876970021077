import React from 'react';
import { Layout } from 'components';
import { Link } from '@reach/router';
import info from '../data.json';

const newpageTitle = info.filter((item: any) => {
  return item.pagetitle === '404 Page';
});
const NotFoundPage = () => (
  <Layout
    pageTitle={newpageTitle[0].title}
    description={newpageTitle[0].description}
    image={newpageTitle[0].image}
  >
    <div className="error-msg">
      <h1>Oops, that's a 404</h1>
      <Link to="/">Back to homepage</Link>
    </div>
  </Layout>
);

export default NotFoundPage;
